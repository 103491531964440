;(function() {
	var small = 640
	var medium = 1024
	var headerBreakPoint = 991
	var $window = $(window)
	var locale = {
		austria: 'at',
		australia: 'au',
		belgium: 'be',
		brazil: 'br',
		canada: 'ca',
		czech: 'cz',
		denmark: 'dk',
		finland: 'fi',
		germany: 'de',
		hongkong: 'hk',
		italy: 'it',
		japan: 'jp',
		norway: 'no',
		netherlands: 'nl',
		newzealand: 'nz',
		singapore: 'sg',
		southafrica: 'za',
		sweden: 'se',
		switzerland: 'ch',
		taiwan: 'tw',
		unitedkingdom: 'uk',
		usa: 'us',
		deutschland: 'de',
		schweiz: 'ch',
		österreich: 'at',
		belgique: 'be',
		suisse: 'ch',
		belgië: 'be',
		nederland: 'nl'
	}
// height script ------------
// Image TextSwiper
$(document).ready(function () {
	if ($(window).width() >= small) {
		function equaliseIt() {
			$('.slick-track').each(function () {
				var highestBox = 0;
				$('.g-ImageTextIconItem__image', this).each(function () {
					if ($(this).height() > highestBox)
						highestBox = $(this).height();
				});
	
				$('.g-ImageTextIconItem__image .g-Image .g-Image__img', this).height(highestBox);
			});
		}
	}else{
		function equaliseIt() {
			$('.slick-track').each(function () {
				var highestBox = 0;
				$('.g-ImageTextIconItem__content', this).each(function () {
					if ($(this).height() > highestBox)
						highestBox = $(this).height();
				});
	
				$('.g-ImageTextIconItem__content', this).height(highestBox);
			});
		}
	}
	//call the function at page load 
	equaliseIt();  
});
$(document).ready(function () {

	function equaliseIt2() {
		$('.js-uniformHeight ').each(function () {
			var highestBox = 0;
			$('.g-ImageTextVP__contentsBlock__text .g-Text', this).each(function () {
				if ($(this).height() > highestBox)
					highestBox = $(this).height();
			});

			$('.g-ImageTextVP__contentsBlock__text .g-Text ', this).height(highestBox);
		});
	}

	//call the function at page load
	equaliseIt2();  
});
$(document).ready(function () {

	function equaliseIt3() {
		$('.js-uniformHeight ').each(function () {
			var highestBox = 0;
			$('.g-ImageTextVP__contentsBlock__text .g-HeadingTitle', this).each(function () {
				if ($(this).height() > highestBox)
					highestBox = $(this).height();
			});

			$('.g-ImageTextVP__contentsBlock__text .g-HeadingTitle', this).height(highestBox);
		});
	}

	//call the function at page load
	equaliseIt3();  
});

// height script ------------
	$(document).ready(function() {
		//IMAGE CAROUSEL ARROWS APPENDED TO DOTS
		$('.banner-landing-carousel.g-ImageCarouselP').each(function(num, ele) {
			var carousel = $(ele)
			var nav = carousel.find('ul.g-ImageCarouselP__nav')
			var prev = carousel.find('.g-ImageCarouselP__prevArrow')
			var next = carousel.find('.g-ImageCarouselP__nextArrow')

			$(nav).wrap("<div class='nav-wrapper'></div>")
			var thisWrapper = carousel.find('.nav-wrapper')

			$(prev).prependTo(thisWrapper)
			$(next).appendTo(thisWrapper)
		})

		//ACCORDION INSIDE ACCORDION
		$('.g-AccordionMenu .accordion .g-HeadingTitle__titleBlock').click(function() {
			$(this).toggleClass('open')
			$(this)
				.siblings('.g-GroupBox')
				.slideToggle(400)
		})

		//        if ($window.width() > medium) {
		////            $('.banner-landing-carousel .g-ImageCarouselP__prevArrow').prependTo('.banner-landing-carousel ul.g-ImageCarouselP__nav')
		////            $('.banner-landing-carousel .g-ImageCarouselP__nextArrow').appendTo('.banner-landing-carousel ul.g-ImageCarouselP__nav')
		//        }

		//ACCORDION MENU CLOSE WHEN OTHER IS OPEN
		$('.js-accordion').click(function() {
			var parent = $(this).closest('.g-AccordionMenu')
			var otherElements = parent.find('.js-accordion').not(this)
			$(otherElements).each(function(num, ele) {
				var ele = $(ele)
				ele.removeClass('is-radius--open').addClass('is-radius--close')
				ele
					.find('.is-open')
					.removeClass('is-open')
					.addClass('is-close')
			})
		})

		if ($window.width() > small) {
			//TILE LINK VERTICAL CAROUSEL
			if (!$.fn.isAuthorring()) {
				$('.tileLinkVert .g-TileLinkVP__list').slick({
					slidesToShow: 3,
					slidesToScroll: 1,
					dots: true
				})
			}
		}

		//BANNER LINK PACK CAROUSEL
		if (!$.fn.isAuthorring()) {
			$('.g-BannerLinkP__list').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: true,
				arrows: false,
				centerMode: true,
				focusOnSelect: true
			})
		}
		if ($window.width() <= small) {
			//TILE ITEM PACK CAROUSEL
			if (!$.fn.isAuthorring()) {
				$('.g-Column.tile-item-slider .g-Column__inner').slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					arrows: false,
					centerMode: true,
					focusOnSelect: true
				})
			}
		}

		if ($window.width() <= headerBreakPoint) {
			//HEADER MENU
			$('.mainnav.g-ListP > .g-ListP__ul > .g-ListP__ul__item').each(function(num, ele) {
				var span = "<span class='dropdown-btn'></span>"
				if ($(ele).find('.g-GroupBox').length > 0) {
					$(span).appendTo(ele)
				}
			})

			$('.menu-btn').click(function() {
				$('.ksd-header-container').toggleClass('opened')
				$(this)
					.toggleClass('active')
					.siblings('.mainnav-container-inner')
					.toggle()
			})
			//TILE LINK VERTICAL CAROUSEL
			// $('.g-TileLinkVP__list').slick({
			//     dots: true,
			// });

			$('.ksd-header-container .mainnav > ul.g-ListP__ul .g-ListUnit > .cmn-richtext').click(function(e) {
				var li = $(this).closest('.g-ListP__ul__item')

				if (li.hasClass('active')) {
					li.removeClass('active')
						.find('.g-GroupBox')
						.slideUp()
				} else {
					$('.ksd-header-container li.g-ListP__ul__item.active')
						.removeClass('active')
						.find('.g-GroupBox')
						.slideUp()
					li.addClass('active')
						.find('.g-GroupBox')
						.slideDown()
				}
			})
		}

		//HEADER SCROLL
		var carousel = $('.g-ImageCarouselP')[0]

		var scrollToChange = function() {
			if (carousel != undefined) {
				var header = $('.ksd-header-container')
				var carouselBottom = $(carousel).offset().top + $(carousel).height()
				var headerBottom = header.offset().top + header.height()

				if (headerBottom > carouselBottom) {
					header.addClass('scrolled-down')
				} else {
					header.removeClass('scrolled-down')
				}
			}
		}

		$window.scroll(function() {
			scrollToChange()
		})

		//CHANGE COUNTRY MODAL

		$('.change-country').click(function() {
			$('.contact-us-modal').removeClass('open')
			//            $('.g-BrFooter').removeClass('contact-us-opened');
			$('.change-country-modal').toggleClass('open')
		})

		$('.close-modal').click(function() {
			$(this)
				.closest('.g-GroupBox')
				.removeClass('open')
			//            $('.g-BrFooter').removeClass('contact-us-opened');
			resetForm($('form#contactUsForm'))
		})

		$('.change-country-modal span.g-ListUnit').click(function() {
			$(this)
				.children('.g-TextLinkP')
				.slideToggle()
		})

		//CONTACT US MODAL

		function getLocale(country, locale, input) {
			if (country.length > 0) {
				var code = country.toLowerCase().trim()
				code = code.replace(/\s+/g, '')
				for (var i in locale) {
					if (i == code) {
						$('.selectDropDown.country').removeClass('has-feedback')
						$('.selectDropDown.country label.error').hide()
						return input.val(locale[i])
					}
				}
			} else {
				input.val('')
				$('.invalid-submit .selectDropDown.country').addClass('has-feedback')
				$('.selectDropDown.country label.error').show()
			}
		}

		$('.selectDropDown .g-ListUnit').click(function() {
			var clickedOption = $(this)
			var dropDown = clickedOption.closest('.selectDropDown')
			var otherDropDowns = $('.selectDropDown')
				.not(dropDown)
				.removeClass('open')
			var result = clickedOption.hasClass('default') ? '' : clickedOption.text()

			if (clickedOption.hasClass('active')) {
				dropDown.toggleClass('open')
				return
			} else {
				dropDown.find('.g-ListUnit.active').removeClass('active')
				clickedOption.addClass('active')
				dropDown.removeClass('open')

				if (dropDown.hasClass('brand')) {
					$("input[name='selectbrand']").val(result)
				} else if (dropDown.hasClass('country')) {
					getLocale(result, locale, $("input[name='countryPreference']"))
				} else if (dropDown.hasClass('how-can-we-help')) {
					$("input[name='howtohelp']").val(result)
				}
			}
		})
		$('span.dropdown-btn.g-ListUnit.l-ListUnit span.cmn-richtext').click(function() {
			$('.dropdown-item .g-ListUnit.dropdown-btn .g-GroupBox').slideToggle()
        })
        
		$('.contact-us-checbox input').change(function() {
			var input = $(this)
			var container = input.closest('.contact-us-checbox')

			if (input.is(':checked')) {
				container.addClass('checked')
			} else {
				container.removeClass('checked')
			}
		})

		$('input.form_field_text, textarea.form_field_textarea').focusin(function() {
			$(this)
				.closest('.form_row')
				.addClass('focused')
		})

		$('input.form_field_text, textarea.form_field_textarea').focusout(function() {
			$(this)
				.closest('.form_row')
				.removeClass('focused')
		})

		$('.contact-us .g-ButtonUnit__link,.contact-us.g-ButtonUnit__link').click(function() {
			$('.change-country-modal').removeClass('open')
			//            $('.g-BrFooter').addClass('contact-us-opened');
			$('.contact-us-modal').addClass('open')
		})

		$('.contact-us-modal textarea.form_field_textarea')
			.closest('.form_row')
			.addClass('messageBox')

		$('input[name="firstName"]').attr('maxlength', '40')
		$('input[name="lastName"]').attr('maxlength', '40')
		$('input[name="phoneNumber"]').attr('maxlength', '20')
		$('input[name="email"]').attr('maxlength', '255')
		$('textarea[name="comment"]').attr('maxlength', '1000')

		var form = $('form[name="contactUsForm"]')
		var phone = $('input[name="phoneNumber"]')

		phone.on('keypress', function(e) {
			return 0 == this.selectionStart
				? e.metaKey || e.which <= 0 || 8 == e.which || /[0-9|+|-]/.test(String.fromCharCode(e.which))
				: e.metaKey || e.which <= 0 || 8 == e.which || /[0-9|-]/.test(String.fromCharCode(e.which))
		})

		$.validator.addMethod('validationEmail', function(value, element) {
			var regex = /^[\w"+-.]+@((\d?\d?\d?\.){3}(\d?\d?\d?)|\[(\d?\d?\d?\.){3}(\d?\d?\d?)\]|([\w]+\.[\w]+)(\.[\w]+)?)$/
			return this.optional(element) || regex.test(value)
		})

		if (phone.length > 0) {
			if (phone.val().length == 0) {
				$('input[name="callAllowed"]').attr('disabled', true)
				$('#callAllowed').css('display', 'none')
			}
		}

		phone.keyup(function() {
			var len = $('input[name="phoneNumber"]').val().length
			if (len >= 5) {
				$('input[name="callAllowed"]').attr('disabled', false)
				$('#callAllowed').css('display', 'block')
			} else {
				$('#callAllowed').css('display', 'none')
				$('input[name="callAllowed"]').attr('disabled', true)
			}
		})

		var validator = form.validate({
			ignore: '',
			rules: {
				firstName: {
					maxlength: 39
				},
				lastName: {
					maxlength: 39
				},
				email: {
					required: true,
					validationEmail: true,
					maxlength: $('input[name="email"]').attr('maxlength')
				},
				phoneNumber: {
					//required: true,
					minlength: 05,
					maxlength: 19
				},
				callAllowed: {
					required: function(element) {
						return phone.is(':filled')
					}
				},
				comment: {
					required: true,
					maxlength: 499
				},
				tcAgreed: {
					required: true
				},
				countryPreference: {
					required: true
				}
			},
			messages: {
				firstName: {
					maxlength: $('#fisrt_name_msg').text()
				},
				lastName: {
					//required: $('#last_name_msg1').text(),
					maxlength: $('#last_name_msg').text()
				},
				email: {
					required: $('#email_msg1').text(),
					validationEmail: $('#email_msg1').text(),
					maxlength: $('#email_msg2').text()
				},
				phoneNumber: {
					//required: $('#phone_msg1').text(),
					minlength: $('#phone_min').text(),
					maxlength: $('#phone_max').text()
				},
				comment: {
					required: $('#comment_msg').text(),
					maxlength: $('#comment_msg2').text()
				},
				tcAgreed: {
					required: $('#tc_error_msg').text()
				},
				callAllowed: {
					required: $('#phone_msg1').text()
				},
				countryPreference: {
					required: $('#subject_msg1').text()
				}
			},
			errorElement: 'label',
			errorPlacement: function(error, element) {
				error.addClass('help-block')
				if (element.prop('type') === 'checkbox') {
					$(element)
						.closest('.form_row')
						.append(error)
				} else if (element.attr('name') == 'countryPreference') {
					$('.selectDropDown.country')
						.addClass('has-feedback')
						.append(error)
				} else if (element.attr('name') == 'email') {
					$(element)
						.closest('.form_row')
						.addClass('has-feedback')
						.append(error)
				} else if (element.attr('name') == 'phoneNumber') {
					$(element)
						.closest('.form_row')
						.append(error)
				} else if (element.attr('name') == 'comment') {
					$(element)
						.closest('.form_row')
						.append(error)
				}
			},
			success: function(label, element) {
				if ($(element).attr('name') == 'email') {
					$(element)
						.closest('.form_row')
						.removeClass('has-feedback')
				}
			},
			highlight: function(element, errorClass, validClass) {
				$(element)
					.closest('.form_row')
					.addClass('has-feedback')
			},
			unhighlight: function(element, errorClass, validClass) {
				$(element)
					.closest('.form_row')
					.removeClass('has-feedback')
			},
			invalidHandler: function() {
				form.addClass('invalid-submit')
			},
			submitHandler: function(form1) {
				$('.loader-section').show()
				form.removeClass('invalid-submit')
				var countryPreference = $('input[name="countryPreference"]').val()
				var requestData = {
					firstName: $('input[name="firstName"]').val(),
					lastName: $('input[name="lastName"]').val(),
					email: $('input[name="email"]').val(),
					phone: $('input[name="phoneNumber"]').val(),
					subBrand: $('input[name="selectbrand"]').val(),
					callAllowed: $('input[name="callAllowed"]').prop('checked'),
					customerCopyRequired: $('#contactUsForm_customerCopyRequired-0').prop('checked'),
					tcAgreed: $('#contactUsForm_tcAgreed-0').prop('checked'),
					brandId: $('input[name="brandId"]').val(),
					countryPreference: countryPreference,
					languagePreference: $('input[name="languagePreference"]').val(),
					feedback: {
						comment: $('textarea[name="comment"]').val(),
						subject: $('input[name="howtohelp"]').val()
					}
				}
				//                alert(JSON.stringify(requestData));
				var domain = $('input[name="apiDomain"]').val()
				$.ajax({
					type: 'POST',
					url: domain + '/api/customer/contact-us',
					headers: {
						'Content-Type': 'application/json'
					},
					data: JSON.stringify(requestData),
					dataType: 'json',
					cache: false,
					timeout: 60000,
					success: function(data) {
						form.hide()
						$('.contact-us-heading').hide()
						$('.contact-us-modal .thanks').show()
					},
					error: function(xhr, textStatus) {
						form.hide()
						$('.contact-us-heading').hide()
						$('.contact-us-modal .sorry').show()
					}
				})
			}
		})

		//RESET FORM

		var resetForm = function(form) {
			form.trigger('reset')
			validator.resetForm()
			$('.loader-section').hide()
			form.show()
			$('.contact-us-heading').show()
			$('.contact-us-modal .thanks').hide()
			$('.contact-us-modal .sorry').hide()
			$('.contact-us-checbox').removeClass('checked')

			$('.selectDropDown.brand .active.g-ListUnit, .selectDropDown.country .active.g-ListUnit').removeClass('active')
			$('.selectDropDown.brand .default.g-ListUnit, .selectDropDown.country .default.g-ListUnit').addClass('active')
		}

		setTimeout(function() {
			var imageTextSwiper = $('.js-ImageTextCarouselP')

			var initializeSwiper = function(ele) {
				var imageTextSwiperList = ele.find('.g-ImageTextCarouselP__list')

				if (imageTextSwiperList.length > 0) {
					var imageTextSwiperPackAutoplay = ele.data('autoplay')
					var imageTextSwiperPackAutoplayspeed = ele.data('autoplayspeed')
					var imageTextSwiperPackTransitionEffect = ele.data('fade')

					if (!$.fn.isAuthorring()) {
						if (imageTextSwiperPackAutoplay) {
							imageTextSwiperList.slick({
								autoplay: false,
								autoplaySpeed: imageTextSwiperPackAutoplayspeed * 1000,
								fade: imageTextSwiperPackTransitionEffect,
								dots: true,
								responsive: [
									{
										breakpoint: 1024,
										settings: {
											arrows: false
										}
									},
									{
										breakpoint: 600,
										settings: {
											arrows: true
										}
									}
								]
							})
						} else {
							imageTextSwiperList.slick({
								fade: imageTextSwiperPackTransitionEffect,
								dots: true,
								responsive: [
									{
										breakpoint: 1024,
										settings: {
											arrows: false
										}
									},
									{
										breakpoint: 600,
										settings: {
											arrows: true
										}
									}
								]
							})
						}
					}
				}
			}

			imageTextSwiper.each(function(num, ele) {
				var $this = $(ele)
				$this.find('.g-ImageTextCarouselP__list').slick('unslick')
				initializeSwiper($this)
			})
		})

		if ($(window).width() <= small) {
			setTimeout(function() {
				$('.g-ImageTextSwiper').each(function(num, ele) {
					var carousel = $(ele)
					var dots = carousel.find('.slick-dots')
					var prev = carousel.find('.slick-prev')
					var next = carousel.find('.slick-next')
					var nav = carousel.find('ul.slick-dots')

					$(nav).wrap("<div class='nav-wrapper'></div>")
					var thisWrapper = carousel.find('.nav-wrapper')
					$(prev).prependTo(thisWrapper)
					$(next).appendTo(thisWrapper)
				})
			})
		}

		if (!$.fn.isAuthorring()) {
			setTimeout(function() {
				$('.g-ImageTextSwiper .g-ImageTextIconItem').each(function(num, ele) {
					var carousel = $(ele)

					var footNote = carousel.find($('.g-Text.foot-note')).detach()
					var container = carousel.find($('.g-ImageTextIconItem__inner'))

					footNote.insertAfter(container)
				})
			})
        }
        
        
		
	})
})()

